<template>
    <div>
        <div class="filter-panel">
            <CSSelect>
                <el-date-picker
                        type="datetime"
                        v-model="queryParams.startDate"
                        format="yyyy-MM-dd HH:mm"
                        value-format="yyyy-MM-dd HH:mm"
                        placeholder="请选择开始时间"
                        :editable="false"
                />
            </CSSelect>
            <span class="inline-gray" style="margin-left: -10px;"></span>
            <CSSelect>
                <el-date-picker
                        type="datetime"
                        v-model="queryParams.endDate"
                        format="yyyy-MM-dd HH:mm"
                        value-format="yyyy-MM-dd HH:mm"
                        placeholder="请选择结束时间"
                        :editable="false"
                />
            </CSSelect>
            <input type="text" class="cs-input" placeholder="搜索车牌" v-model="queryParams.search">
            <CSSelect>
                <select v-model="queryParams.type">
                    <option value="">车辆类型不限</option>
                    <option value="1">临时车</option>
                    <option value="2">月租车</option>
                    <option value="3">免费车</option>
                </select>
            </CSSelect>
            <CSSelect>
                <select v-model="queryParams.state">
                    <option value="">状态不限</option>
                    <option value="1">已入场</option>
                    <option value="2">已出场</option>
                    <option value="3">自动关闭</option>
                </select>
            </CSSelect>
            <!--            <CSSelect>
                            <select v-model="queryParams.parkingKey">
                                <option value="">全部停车场</option>
                            </select>
                        </CSSelect>-->
            <button class="btn btn-primary" @click="queryParkRecord()">查询</button>
        </div>

        <div class="result-panel">
            <CSTable :thead-top="filterHeight">
                <template v-slot:thead>
                    <tr>
                        <th>车牌</th>
                        <th>车辆类型</th>
                        <th>入场时间</th>
                        <th>出场时间</th>
                        <th>停车时长</th>
                        <th>出入场图片</th>
                        <th>状态</th>
                        <th>停车场</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <template v-if="records.length > 0">
                        <tr v-for="item in records" :key="item.id">
                            <td>{{item.carNumber || '-'}}</td>
                            <td v-if="item.type === 1">临时车</td>
                            <td v-else-if="item.type === 2">月租车</td>
                            <td v-else-if="item.type === 3">免费车</td>
                            <td class="date-td">{{item.inTime || '-'}}</td>
                            <td class="date-td">{{item.outTime || '-'}}</td>
                            <td>{{item.parkingDuration ? item.parkingDuration + '分钟' : '-'}}</td>
                            <td>
                                <div class="allow-click" v-if="item.outPicture || item.inPicture"
                                     @click="lookCarPicture(item)"
                                >
                                    查看
                                </div>
                                <template v-else>-</template>
                            </td>
                            <td>{{item.state===1?'已入场':item.state===2?'已出场':item.state===3?'自动关闭':'-'}}</td>
                            <td>{{item.parkingName || '-'}}</td>
                        </tr>
                    </template>
                </template>
            </CSTable>
            <pagination/>
        </div>


        <CSDialog :dialog-visible="pictureDialog.visible"
                  dialog-title="查看出入场图片"
                  dialog-width="970px"
                  :dialog-show-confirm-btn="false"
                  dialog-cancel-btn-text="关闭"
                  @onClose="pictureDialog.visible = false"
        >
            <template v-slot:dialog-content>
                <div class="park-photo">
                    <div>
                        <div class="title">入场图片</div>
<!--                        <img :src="pictureDialog.data.inPicture" alt="">-->
                        <CSImage :srcImg="pictureDialog.data.inPicture" height="280px" style="margin:30px 0">

                        </CSImage>
                        <div>入场时间：{{pictureDialog.data.inTime || '-'}}</div>
                    </div>
                    <div >
                        <div class="title">出场图片</div>
<!--                        <img v-if="pictureDialog.data.outPicture" :src="pictureDialog.data.outPicture" alt="">-->
                        <CSImage v-if="pictureDialog.data.outPicture" :srcImg="pictureDialog.data.outPicture" height="280px"  style="margin:30px 0">

                        </CSImage>
                        <div v-else style="background-color: #f0f0f0;height: 280px;margin: 30px 0;">
                            <div style="display: flex;align-items: center;justify-content: center;flex-wrap: wrap;width: 100%;height: 100%;align-content: center">
                                <img src="../../assets/stopCar.png" style="width: 70px;height: 50px ;margin: 0;display: inline-block" />
                                <div class="title" style="color: #999999;width: 100%;margin-top: 10px">暂无图片</div>
                            </div>
                        </div>
                        <div>出场时间：{{pictureDialog.data.outTime || '-'}}</div>
                    </div>
                </div>
            </template>
        </CSDialog>
    </div>
</template>

<script>
    import CSSelect from "@/components/common/CSSelect";
    import Pagination from "@/components/Pagination";
    import dayjs from "dayjs";
    import CSDialog from "@/components/common/CSDialog";
    import {parkingInOutLogUrl} from "@/requestUrl";
    import CSTable from "@/components/common/CSTable";
    import CSImage from "@/components/common/CSImage";
    export default {
        name: "ParkRecords",
        components: {CSTable, CSDialog, Pagination, CSSelect,CSImage},
        data() {
            return {
                queryParams: {
                    startDate: dayjs().subtract(30, 'day').format('YYYY-MM-DD 00:00'),
                    endDate: dayjs().format('YYYY-MM-DD 23:59'),
                    search: '',
                    type: '',
                    isOut: '',
                    parkingKey: '',
                    state: ""
                },
                records: [],
                filterHeight: 0,
                pictureDialog: {
                    visible: false,
                    data: {},
                }
            }
        },
        created() {
            this.queryParkRecord();
            document.body.addEventListener('keydown', e => {
                if (e.key === 'Enter') {
                    this.queryParkRecord();
                }
            })
            this.$vc.on(this.$route.path, 'pagination_page', 'event', this.queryParkRecord);
        },
        mounted() {
            this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
            window.addEventListener('resize', () => {
                this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
            })
        },
        updated() {
            this.filterHeight = document.querySelector('.filter-panel').offsetHeight;
        },
        methods: {
            /**
             * 查看车辆照片
             * @param {*} carLogInfo 车辆进出记录信息
             * */
            lookCarPicture(carLogInfo) {
                this.pictureDialog = {
                    visible: true,
                    data: carLogInfo,
                };
            },
            /**
             * 查询停车记录
             * @param {Number} pageNo 页码
             * @param {Number} pageSize 查询的数量
             * */
            queryParkRecord(pageNo = 1, pageSize = 10) {
                this.$fly.post(parkingInOutLogUrl, {
                    regionCode: this.$vc.getCurrentRegion().code,
                    ...this.queryParams,
                    pageNo,
                    pageSize
                })
                    .then(res => {
                        if (res.code === 0) {
                            this.records = res.data.datas ?? [];
                            if (pageNo === 1) {
                                this.$vc.emit(this.$route.path, 'pagination', 'init', {
                                    total: res.data.total,
                                    pageSize,
                                    currentPage: pageNo
                                })
                            }
                        }
                    })
            }
        }
    }
</script>

<style scoped lang="stylus">
    .result-panel-header
        span
            font-weight bold
            margin-right 40px
            color #000

    .park-photo
        padding 30px
        line-height 1

        & > div
            font-size 24px
            display inline-block
            width 440px
            text-align left

            .title
                text-align center

            &:not(:last-of-type)
                margin-right 30px

            img
                width 100%
                height 280px
                margin 30px 0
</style>
